/* Mixins
  =============== */

// Media query for mobile layout
@mixin small {
  @media (max-width: #{$sm - 1}) {
    @content;
  }
}

// tablet
@mixin medium {
  @media (min-width: #{$sm}) and (max-width: #{$md - 1}) {
    @content;
  }
}

@mixin medium-down {
  @media (max-width: #{$md - 1}) {
    @content;
  }
}


@mixin medium-up {
  @media (min-width: #{$sm}) {
    @content;
  }
}

// desktop
@mixin large {
  @media (min-width: #{$md}) and (max-width: #{$lg - 1}) {
    @content;
  }
}

@mixin large-up {
  @media (min-width: #{$md}) {
    @content;
  }
}


// wide screen
@mixin xlarge {
  @media (min-width: #{$lg + 1}) {
    @content;
  }
}