body.library {
    h1 {
        font-size: 3.6rem;
        font-weight: $bold;
    }
    h2 {
        font-size: 3rem;
        font-weight: $bold;
    }

    .downloads {
        ul {
            list-style: none;
            padding: 0;
            margin-bottom: 2em;

            li {
                display: flex;
                font-weight: $medium;
                line-height: 2.5rem;
                margin-bottom: 1.2rem;

                padding: 1em;
                background: #ffffff;
                box-shadow: 0 10px 30px 0 rgba(32, 33, 47, 0.08);
                border-radius: $border-radius;

                .name {
                    display: flex;
                    flex-grow: 1;
                    a {
                        color: $accent-color;
                    }
                }
                .size {
                    width: 5em;
                    font-size: $small-font-size;
                }
                .download {
                    display: block;
                    width: 2em;
                    width: 1.3em;
                    height: 1.3em;
                    background: url(/dist/images/icons/download.svg) no-repeat;
                    background-size: auto 100%;
                }
            }
        }
    }
}
