body.home {
    h2,
    h2.title {
        font-size: 3rem;
        font-weight: 700;
        line-height: 1.1;
    }

    .top-bar {
        position: relative;
        #nav-main {
            background: none;
        }
    }

    #header-main {
        position: relative;
        width: 100%;
        min-height: 30rem;
        margin-top: -9.75vw;

        .wrapper {
            position: absolute;
            width: 100%;
            bottom: 13vw;

            @include medium-down {
                left: 0;
                .button {
                    font-size: calc(2vw + 0.8rem);
                }
            }

            .col-12 {
                margin-left: 0;
                @include medium-up {
                    max-width: 45em;
                }

                .hero-text {
                    @extend %headings;
                    line-height: 1.2;
                    max-width: 35vw;
                    color: #fff;
                    font-size: 4vw;
                    text-shadow: $text-shadow;

                    p {
                        margin: 0;
                    }

                    .more {
                        font-size: 1.8rem;
                        color: #fff;
                        font-weight: 400;
                    }
                }
            }
        }

        .img-container {
            img {
                width: 100%;
            }

            @include medium-down {
                padding-bottom: 27vw;
                img {
                    transform: translate3d(-13vw, 12vw, 0)
                        scale3d(1.6, 1.6, 1.6);
                }
            }
        }
    }

    .latest-item {
        margin-top: 3rem;

        .latest-card {
            flex-flow: row-reverse;
            box-shadow: none;
            img {
                border-radius: 1rem;
                width: 100%;
            }
            @include medium-down {
                flex-flow: column;
            }
        }
    }

    .news-events {
        margin-top: 8rem;
        padding-top: 9rem;
        background: url(/dist/images/home-bg-gradient.svg) no-repeat;
        background-size: 100%;

        @include medium-down {
            background-size: 300%;
        }

        .card {
            .wrapper {
                background: none;
                box-shadow: none;
                padding: 0;

                img {
                    border-radius: $border-radius;
                    margin-bottom: 0.5rem;
                }
            }

            @include large-up {
                &.col-md-4 {
                    max-width: 30%;
                }
            }
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS - fix buggy flexbox  */
                display: block !important;
                .wrapper {
                    display: block !important;
                    flex-grow: 0;
                }
            }
        }
    }

    .signup {
        padding: 5rem 0;

        .error {
            position: absolute;
            margin-top: .5em;
            margin-left: 2rem;
        }

        /* search bar */

        .search-bar {
            position: relative;
            width: 100%;
            background-color: #fff;
            border: none;
            border-radius: 2.8rem;
            box-shadow: $box-shadow;
            box-shadow: 0 5px 20px 0 rgba(43, 133, 217, 0.25);

            input.query {
                border: none;
                padding: 1.2rem 2rem;
                border-radius: 2.2rem;
                display: inline-block;
                vertical-align: middle;
                width: calc(100% - 7.6em);

                &::placeholder {
                    color: brighten(40%);
                }
            }

            button {
                margin: 0;
                width: 7em;
                line-height: 0.7;
                vertical-align: middle;
                position: absolute;
                right: 0.5rem;
                top: 0.5rem;
                bottom: 0.5rem;
            }
        }
    }
}
