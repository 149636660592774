// HTML base
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font: $font-style $font-variant $font-weight #{$font-size}/#{$line-height}
        $font-family;
    font-size: $font-size;
}

// Reset box sizing to border box (inherited from html root)
*,
*::before,
*::after {
    box-sizing: inherit;
}

// Body base
body {
    position: relative;
    color: $font-color;
    background: $background;
    font-size: $body-font-size;
}

section {
    position: relative;
    z-index: 3; /* this is needed so sections will be aboven possible artifacts */
}

@include small {
    .hide-small,
    .hide-medium-down {
        display: none !important;
    }
    html {
        font-size: 53.125%;
    }

    h1 {
        font-size: $h1-mobile;
    }
}

@include medium {
    .hide-medium,
    .hide-medium-down {
        display: none !important;
    }
}

@include medium-down {
    body {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }
    #main {
        flex-grow: 9999;
    }

    .container,
    .container-full {
        padding-left: 1.25em !important;
        padding-right: 1.25em !important;

        > .grid {
            margin-right: -1.25em !important;
            margin-left: -1.25em !important;
        }
    }
}

@include large {
    .hide-large,
    .hide-large-up {
        display: none !important;
    }

    .flex-large,
    .flex-large-up {
        &,
        > * {
            display: flex;
        }
    }

    html {
        font-size: 56.25%;
    }
}

@include xlarge {
    .hide-xlarge,
    .hide-large-up {
        display: none !important;
    }

    .flex-large-up {
        &,
        > * {
            display: flex;
        }
    }

    html {
        font-size: 62.5%;
    }
}
