%headings {
    margin: $margins 0 $margins*0.5;
    font-weight: $heading-font-weight;
    font-family: $heading-font-family;
    line-height: $heading-line-height;
    color: $heading-font-color;
}

section, section.grid {
    padding-top: $content-padding;
    padding-bottom: $content-padding;
    position: relative;
}

section.no-padding {
    padding: 0;
}

p,
ol,
ul,
dl,
table {
    margin: 0.8em 0;
    &:last-child {
        margin: 0;
    }
}

// Remove extra margin for nested lists
ul li ul {
    margin-bottom: 0;
}

ol li ol {
    margin-bottom: 0;
}

// Headings
h1,
h2,
h3,
h4,
h5 {
    @extend %headings;
    &:not(:first-child) {
        margin: 0.8em 0;
    }
    &:first-child {
        margin-top: 0;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
}

// Increased margin on additional headings
h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
    margin-top: 1.33em;
}

// Heading desktop individual styles
h1 {
    font-size: $h1;
}
h2 {
    font-size: $h2;
    font-weight: 400;
}
h3 {
    font-size: $h3;
}
h4 {
    font-size: $h4;
}
h5 {
    font-size: $h5;
}

// Link styling
a {
    color: $link-color;
    text-decoration: underline;
    &:hover,
    &:active,
    &:focus {
        color: $link-hover-color;
        text-decoration: none;
    }
}

// Highlight
mark {
    background: $highlight;
    padding: 0 0.2em;
}

// Blockquote
blockquote {
    margin: 0 0 $margins*2 0;
    border-left: 2px;
    padding: 0 1.5em;
    font-size: 1.5em;
    cite {
        display: block;
        margin-top: $margins;
        font-size: 1rem;
        text-align: right;
    }
}

// Code block styling
pre {
    border: 0;
    border-radius: $border-radius;
    background: $code-background;
    padding: 1em;
    tab-size: 2;
    color: $code-color;
    font-family: $code-family;
    font-size: $code-size;
    margin: 0 0 $margins 0;
    code {
        font-family: $code-family;
        line-height: 1.2;
    }
}

address {
    font-style: normal;
}

// Keyboard input
kbd {
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
    color: #333;
    display: inline-block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.4;
    margin: 0 .1em;
    padding: .1em .6em;
    text-shadow: 0 1px 0 #fff;
}

// Inline code styling
:not(pre)>code {
    color: $code-color;
    background: $code-background;
    font-family: $code-family;
    font-size: $code-size;
}

// Line break
hr {
    height: 0;
    border: 0;
    border-top: $borders;
}

// Definition list
dt {
    font-weight: 600;
}

dd {
    margin-bottom: .5em;
}

.style-light-blue {
    background-color: $light-blue;
}

.pattern-bg {
    background: url(/dist/images/background-green.svg) no-repeat;
    background-size: cover;
}

.pattern-bg-blue {
    background: url('/dist/images/background-blue.svg') no-repeat;
    background-size: cover;
}


.big-title h2,
.big-title h3,
.big-title h4,
.big-title h5 {
    font-size: $h1;
    font-weight: 500;
}

.trigger {
    cursor: pointer;
}

.table-wrapper {
    overflow-y: hidden;
    overflow-x: auto;

    table {
        max-width: none;
        min-width: 40em;
    }

}