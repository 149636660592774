/** =================== MONTSERRAT =================== **/

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("/dist/fonts/Montserrat-Light.eot");
    src: url("/dist/fonts/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
         url("/dist/fonts/Montserrat-Light.woff2") format("woff2"),
         url("/dist/fonts/Montserrat-Light.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("/dist/fonts/Montserrat-Regular.eot");
    src: url("/dist/fonts/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
         url("/dist/fonts/Montserrat-Regular.woff2") format("woff2"),
         url("/dist/fonts/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("/dist/fonts/Montserrat-Medium.eot");
    src: url("/dist/fonts/Montserrat-Medium.eot?#iefix") format('embedded-opentype'),
         url("/dist/fonts/Montserrat-Medium.woff2") format("woff2"),
         url("/dist/fonts/Montserrat-Medium.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("/dist/fonts/Montserrat-SemiBold.eot");
    src: url("/dist/fonts/Montserrat-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("/dist/fonts/Montserrat-SemiBold.woff2") format("woff2"),
         url("/dist/fonts/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("/dist/fonts/Montserrat-Bold.eot");
    src: url("/dist/fonts/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
         url("/dist/fonts/Montserrat-Bold.woff2") format("woff2"),
         url("/dist/fonts/Montserrat-Bold.woff") format("woff");
}