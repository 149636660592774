/* Tables
  =============== */

// Table expands to full width of containing element
table, .table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 100%;
}

// Extra border underneath header
thead th {
    border-bottom: 2px solid $border-color;
}

// Extra border above footer
tfoot th {
    border-top: 2px solid $border-color;
}

td {
    border-bottom: 1px solid $border-color;
}

th,
td {
    text-align: left;
    padding: .3em;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
}

caption {
    padding: $padding 0;
    caption-side: bottom;
    color: $caption;
}

.striped-table tbody tr:nth-child(odd) {
    background-color: $stripes;
}

@include large-up {
    .table {
        display: table;
        border: $borders;
        border-radius: $border-radius;


        .thead {display: table-header-group;}
        .tbody {display: table-row-group;}


        .tr {
            display: table-row;

            > .th, > .td {
                padding: 0.25em 0.75em;
                display: table-cell;
                border-bottom: $borders;                    
            }

            .th {
                padding: 0.75em;
            }

            &:last-child .td {
                border-bottom: none;
            }
        }
    }    
}
