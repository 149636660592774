/* pagination */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    padding: $content-padding;

    a, span {
        //aliment
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.2em;

        //shape
        border: $borders;
        border-radius: 50%;
        height: 2.75em;
        width: 2.75em;

        //text
        text-decoration: none;
        font-size: $small-font-size;
        font-weight: $semibold;
        color: $primary-color; 

        &.active {
            background-color: $primary-color;
            color: #fff;
        }

        &:hover:not(.active) {
            text-decoration: none;
            background-color: lighten($primary-color, 62%);
            //background: #DEE2E6;
        }

        &:active:not(.active) {
            color: #fff;
            background-color: $primary-color;
        }
    }
}