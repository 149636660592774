nav ul,
nav li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.primary {
    /*background: linear-gradient(to bottom, #fff 10rem, #5955AC 10rem);*/
    a {
        color: $heading-font-color;
        font-weight: $medium;
        text-decoration: none;
    }
}

.nav-secondary {
    background: $background;
    a {
        color: $primary-color;
        text-decoration: none;
    }
}

nav {
    li.active a,
    li a:hover {
        color: $accent-color;
        text-decoration: none;
    }
}

#nav-main {
    height: 9.75vw;
    background: url(/dist/images/Menubar.svg) no-repeat;
    background-size: contain;
}

@include medium-down {
    #nav-main {
        background-image: linear-gradient(
            to bottom,
            #fff,
            #fff 3.1em,
            $background 3.1em
        );
        flex-grow: 9999;

        .icons,
        .logo {
            height: 3.1em;
            overflow: hidden;
            background: #ffffff;
            box-shadow: 0 3px 19px 0 rgba(0, 0, 0, 0.08);
            z-index: 1;
        }

        .primary {
            margin: 2rem 0;
            flex-direction: column;
            font-size: 2.2rem;
            line-height: 1.8;
        }

        .other {
            padding: 0;
            .flex {
                flex-direction: column;
                padding: $content-padding;

                > li {
                    padding: 0 0.75em;
                    &,
                    a {
                        color: #fff;
                    }
                }

                .search {
                    order: 1;
                    margin-bottom: $margins;

                    .trigger {
                        display: none;
                    }
                    form {
                        display: block;
                        position: relative;

                        input {
                            padding-right: 3em;
                        }

                        &:before {
                            position: absolute;
                            display: block;
                            content: "";
                            background: url(/dist/images/Icons/mobile-search.svg)
                                no-repeat bottom right;
                            background-size: auto 90%;
                            top: 0;
                            right: 0.18em;
                            height: 100%;
                            width: 2.5em;
                            height: 2.5em;
                        }
                    }
                }
                .mobile-menu {
                    order: 2;
                    .primary {
                        width: 100%;
                        > li {
                            position: relative;
                            padding-right: 2.5em;
                            cursor: pointer;

                            &:not(:last-of-type) {
                                border-bottom: 1px solid
                                    rgba(255, 255, 255, 0.5);
                            }

                            > a {
                                display: block;
                                padding: 0.5em 0;
                                font-size: $h3;
                            }

                            &:after {
                                display: block;
                                content: "+";
                                position: absolute;
                                right: 0;
                                top: 0;
                                padding: 0.25em 0 0.25em 1em;
                                font-size: 2em;
                                line-height: 1;
                                transition: transform 0.3s,
                                    transform-origin 0.3s;
                            }
                            &.active:after {
                                transform: rotate(-45deg);
                                transform-origin: 1.2em 0.8em;
                            }

                            ul {
                                font-weight: 300;
                                display: none;
                                margin-bottom: 1em;

                                li {
                                    margin-bottom: 0.25em;
                                }
                            }

                            &.active ul {
                                display: block;
                            }
                        }
                    }
                }

                .user-nav {
                    order: 3;
                    flex-direction: column;
                    padding: $margins 0.75em;
                    background-color: lighten($background, 15%);

                    > a {
                        display: block;
                        padding: 0.5em 0;
                        font-size: $h3;
                    }

                    a {
                        text-decoration: none;
                        &:hover,
                        &:active {
                            text-decoration: underline;
                        }

                        &.button,
                        &.button:hover,
                        &.button:active {
                            text-decoration: none;
                        }
                    }

                    ul {
                        font-weight: 300;
                        margin-bottom: 1em;

                        li {
                            margin-bottom: 0.25em;
                        }
                    }
                }
            }
            .logo-lrqa {
                width: 15em;
                margin-left: $margins;
                display: block;
                margin: auto;
                margin-bottom: 1.5em;
                max-width: calc(100% - 1.5em);
            }
        }
    }

    .top-bar {
        .t_menu {
            width: 1.6em;
            height: 100%;
            background-image: url(/dist/images/icons/burger.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            float: right;
        }

        .mobile-menu {
            height: calc(100vh - 3.1em);
            background-image: linear-gradient(181deg, #eaf3f8 0%, #ffffff 78%);
        }

        &.active {
            min-height: 100vh;
            .t_menu {
                background-image: url(/dist/images/icons/close.svg);
            }
        }
    }
}

@include large-up {
    .primary {
        font-size: 2rem;
        li {
            margin-left: 1.5em;
        }
    }

    #nav-main .nav-primary {
        height: 7.75vw;
        line-height: 7.75vw;
        white-space: nowrap;

        @include large-up {
            .logo img {
                margin-left: .5rem;
            }
        }
    }

    #nav-main .nav-primary .grid [class$="col-"] {
        height: 10rem;
    }

    #nav-main .nav-primary .flex > * {
        height: 10rem;
    }

    #nav-main .nav-primary .primary {
        float: right;
    }

    #nav-main .nav-secondary li {
        margin-right: 1.5em;
        font-size: $smaller-font-size;
        line-height: 6rem;
    }

    #nav-main .nav-primary .other {
        z-index: 1; /* needs it for the search pop-out */
        > ul {
            flex-direction: row-reverse;
            > * {
                position: relative;
                background-repeat: no-repeat;
                background-position: left 0.5em center;
                background-size: 1em;
                padding-left: 1em;
                cursor: pointer;
            }
        }

        .trigger {
            height: 100%;
            padding-left: 1.5em;
            user-select: none;
            background-repeat: no-repeat;
            background-position: center left;
        }

        .popup {
            position: absolute;
            //z-index: 14;
            top: calc(100% + 0.6em);
            right: -2em;
            box-shadow: $box-shadow;
            box-shadow: $box-shadow-heavy;

            &:before {
                z-index: -1;
                content: "";
                position: absolute;
                background: #fff;
                bottom: calc(100% - 1.9em);
                right: 2em;
                width: 2em;
                height: 2em;
                transform: rotate(-45deg);
            }
        }

        .user-nav {
            .trigger {
                background-image: url(/dist/images/Icons/User.svg);
            }
            a {
                color: $heading-font-color;
                text-decoration: none;
            }

            .popup {
                right: 0;

                background-color: #fff;
                li {
                    line-height: 1;
                    padding: $margins $margins;
                    white-space: nowrap;
                }
                ul:last-child li {
                    background-color: #f4f5f7;
                    margin-top: 15px;
                }
            }
        }

        .search {
            .trigger {
                background-image: url(/dist/images/Icons/looking-glass.svg);
            }
            .popup {
                width: 20em;

                input {
                    border-radius: 0;
                    margin: 0;
                    border: none;
                    padding-top: 1em;
                    padding-bottom: 1em;
                    box-shadow: none;
                }
            }
        }
        .my-order {
            background-image: url(/dist/images/Icon/MyOrder.svg);
        }
    }
}
