/* top-bar */

.top-bar {
    z-index: 10;
    top: 0;

    @include medium-down {
        & {
            min-height: 3.1em; /* needs min-height for tranistion (others don't transition) */
            max-height: 3.1em;
            transition: all 0.4s ease-in-out;
            overflow: hidden;

            .wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
        }

        &.active {
            overflow-y: auto;
            max-height: 100vh;
            min-height: 100vh;
        }
        .t_close {
            display: none;
        }

        .bar .message {
            margin-right: 0;
        }
    }
}

.bottom-bar {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
}

body:not(.home) {
    background: linear-gradient(181deg, #eaf3f8 0%, #ffffff 78%) no-repeat;

    @include medium-down {
        background-image: linear-gradient(181deg, #eaf3f8 0%, #ffffff 10%);
    }
    header {
        margin-top: 2em;
    }
}

.intro {
    p {
        font-weight: $medium;
    }
}

.showcase {
    display: block;
    width: 100%;
    margin: $margins 0;
    border-radius: $border-radius;
    img {
        width: 100%;
        border-radius: 1rem;
    }
}

#modals {
    position: fixed;
    z-index: 100;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .content {
        position: relative;
        background: #fff;
        padding: 4rem;
        .close {
            user-select: none;
            position: absolute;
            top: 0;
            right: 0;
            width: 5rem;
            height: 5rem;
            background: $heading-font-color url(/dist/images/Button/Close.svg)
                no-repeat center;
            background-size: 45%;
        }
    }
}

/* country-select modal */

#modals [data-modal="country-select"] {
    input {
        margin: $padding 0;
    }
}

/* visual header */

#header-main {
    overflow: hidden;
}

#header-main.visual {
    position: relative;
    width: 100%;
    height: 50vw;
    @include large-up {
        margin-top: -10vw;
    }
}

#header-main.visual .img-container {
    position: absolute;
    left: 5%;
    width: 100%;
    height: 100%;
}

#header-main.visual .underlay {
    background: #eaf6fd;
    height: 80%;
    width: 100%;
    position: absolute;
}

#header-main.visual .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(transparent 65%, rgba(0, 0, 0, 0.35));
}

#header-main.visual .img-container img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

#header-main.visual .grid {
    position: absolute;
    bottom: 9vw;
    width: 100%;
    padding: $content-padding;
}

#header-main.visual .hero-text {
    @extend %headings;
    position: absolute;
    bottom: 0;
    color: #fff;
    font-size: $h1;
    max-width: 20em;
    text-shadow: $text-shadow;
}

@include large-up {
    #header-main.visual .col-12 {
        margin-left: calc(18rem - 5vw);
    }
}

@media screen and (min-width: $fullwidth-xlarge-nav-min) {
    #header-main.visual .col-12 {
        margin-left: 0;
    }
}

/* flashes */

.alert {
    padding: 0.5em 0.75em;
    color: #fff;
    font-size: $body-font-size;
    font-weight: 500;
    border-radius: $border-radius;

    &.alert-error {
        background: url(/dist/images/Icons/Alert.svg) no-repeat left 0.5em
            center;
        background-size: 1.5em;
        background-color: $error;
        padding-left: 2.5em;
    }

    &.alert-notice {
        background-color: #39c7ef;
    }
}

/* footer */

@include medium-down {
    #page-footer {
        padding-top: 1.5rem;
    }
    #page-footer .logo {
        text-align: left;
    }
    .text {
        padding-top: 0;
        padding-bottom: 0;
    }
    .other {
        ul {
            text-align: left;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: inline-block;
                margin-right: 3rem;
            }
        }
        a {
            color: $primary-color;
        }
    }
}

@include large-up {
    #page-footer {
        border-top: 1px solid lighten($font-color, 60%);
        height: 14rem;
        margin: 1.5em 0 0;
        width: auto;
    }

    #page-footer .info {
        height: 14rem;
    }

    #page-footer .text {
        display: flex;
        height: 100%;
        font-size: $small-font-size;
        color: $primary-color;
        > div {
            max-width: 40em;
            align-self: center;
        }
    }

    #page-footer .logo {
        display: flex;
        height: 100%;
        > a {
            align-self: center;
        }
    }

    #page-footer a {
        text-decoration: none;
        color: $primary-color;
    }

    #page-footer ul {
        padding-left: 0;
    }

    #page-footer .primary {
        display: flex;
    }

    #page-footer .primary > li {
        width: calc(100% / 5);
        max-width: 10em;
    }

    #page-footer li {
        list-style-type: none;
        margin-left: 2.5vw;
    }

    #page-footer li li {
        a {
            font-size: 1.6rem;
            color: $primary-color;
        }
    }

    #page-footer .other ul {
        float: right;
        display: flex;
        height: 100%;
        font-size: 1.6rem;
        li {
            align-self: center;
        }
    }

    #page-footer .copy {
        border-top: 1px solid lighten($font-color, 40%);
    }

    #page-footer .copy [class^="col-"]:nth-child(2) {
        text-align: right;
    }

    #page-footer .copy ul {
        display: flex;
        font-size: 1.6rem;
        li {
            margin-right: 2.5vw;
            margin-left: 0;
        }
        li a {
            color: $font-color;
        }
    }
}

/* specific page styling */

/* coffalyser */
body.coffalyser {
    h2 {
        font-size: $h4;
    }
    .key {
        font-size: $h2;
        font-weight: 500;
        padding-left: 2.8em;
        background: url(/dist/images/Content/coffalyser-key.svg) no-repeat left
            center;
        background-size: auto 1em;
    }
}

/* main content sections */

/* cards with images */

@include large-up {
    .cards-with-images .card-wrapper {
        // add some margins inside
        width: calc(calc(100% / 3) - #{$margins});
    }
    .card-wrapper {
        display: flex;
        flex-align: columns;
    }
}

/* bar */

.bar {
    background-color: $button-color;
    color: $primary-color;
    overflow: hidden;
    font-size: 1.8rem;

    @include large-up {
        .content {
            min-height: 3.2em;
            line-height: 3.2em;
        }
    }

    @include medium-down {
        .content {
            padding: 0.75em 0;
            text-align: left;
        }
    }

    a,
    a:visited,
    a:hover,
    a:active {
        color: $primary-color;
    }

    .message {
        margin-right: $margins * 2;
        vertical-align: middle;

        @include medium-down {
            & {
                margin-bottom: 0.5em;
            }
        }
    }

    .actions {
        text-align: right;
        text-align-last: right;

        @include medium-down {
            text-align: left;
            text-align-last: left;
        }

        > * {
            text-transform: uppercase;
            vertical-align: middle;
            @include large-up {
                margin-left: $margins * 2;
            }
        }
    }

    .trigger.t_close {
        position: absolute;
        right: 1em;
        width: 1em;
        height: 1em;
        margin-top: 1em;
        background: url(/dist/images/Button/Close.svg) no-repeat center;
        background-size: contain;
        cursor: pointer;

        &:hover {
            opacity: 0.6;
        }

        @include medium-down {
            & {
                margin-top: 0;
            }
        }
    }
}

.cookie-bar {
    background-color: $button-color;
    z-index: 8;
}

.image-with-caption img {
    border-radius: $border-radius * 2;
}

/* small (resusable) components */

/* logo */

.logo {
    @include large-up {
        img {
            vertical-align: middle;
            max-width: 15vw;
        }
    }
    @include medium-down {
        img {
            height: 100%;
            padding: 0.45em 0;
        }
    }
}

.partners {
    img {
        width: 100%;
        max-height: 7.5rem;
        object-fit: contain;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS - fix image squeeze  */
            max-height: none;
        }

    }
    > * {
        margin: auto 0;
        padding: 30px;
    }
}

/* flag icon */
.flag-icon {
    margin-right: 0.5em;
}

/* artifact */
.artifact {
    position: absolute;
}

/* styles */
.bg-blue {
    background: lighten($light-blue-bg, 7.5%);
}
