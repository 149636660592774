body.pilots {
    #page-footer {
        margin-top: 0;
    }
    #map {
        position: relative;
        width: 100%;
        min-height: 30rem;
        margin-top: -9.75vw;
        background-color: $light-blue-bg;
        padding: 0;

        @include medium-down {
            background-color: rgba(114, 169, 59, 0.9);
        }

        .image-container {
            position: relative;
            img {
                display: block;
                width: 100%;
            }

            @include medium-down {
                z-index: -1;
                transform: translate3d(-16vw, 8vw, 0) scale3d(1.6, 1.6, 1.6);
            }

            .locations {
                .location {
                    position: absolute;
                    z-index: 1;

                    &.active {
                        z-index: 2; /* make sure they are on top when active */
                    }

                    .pin {
                        display: block;
                        font-size: 1.3vw;
                        line-height: 1;

                        cursor: pointer;
                        &:hover {
                            h2 {
                                text-decoration: underline;
                            }
                        }
                        &:before {
                            display: block;
                            position: absolute;
                            content: "";
                            background: url(/dist/images/pin.svg) no-repeat;
                            background-size: auto 100%;
                            width: 1.3em;
                            height: 1.3em;
                            object-fit: contain;
                            vertical-align: middle;
                        }
                        h2 {
                            margin-left: 1.5em;
                            display: inline-block;
                            font-size: calc(1.3vw);
                            font-weight: 600;
                        }
                    }

                    .balloon {
                        font-size: calc(1.3vw);
                        line-height: 1;

                        min-width: 12em;
                        position: absolute;
                        bottom: 2em;
                        transform: translateX(calc(-50% + 0.65em));
                        filter: drop-shadow(0 10px 30px rgba(32, 33, 47, 0.08));

                        .wrapper {
                            font-size: calc(1.3vw);
                            line-height: 1;

                            background: #fff;
                            clip-path: polygon(
                                0% calc(100% - 0.5em),
                                calc(50% - 0.5em) calc(100% - 0.5em),
                                calc(50%) 100%,
                                calc(50% + 0.5em) calc(100% - 0.5em),
                                100% calc(100% - 0.5em),
                                100% 0%,
                                0% 0%
                            );
                            ul {
                                margin: 0;
                                list-style: none;
                                padding: 0;
                                padding-bottom: 0.5em;

                                li {
                                    margin: 0.5em 1em;
                                    &:not(:last-of-type) {
                                        border-bottom: solid 1px #eaf3f8;
                                    }
                                    padding: 0.5em 0;

                                    a,
                                    a:visited,
                                    a:hover,
                                    a:active {
                                        color: $primary-color;
                                        font-size: $small-font-size;
                                        font-weight: 600;
                                        line-height: 1.2;

                                        @include medium-down {
                                            font-size: 1.3vw;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include large-up {
            .map-container {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                max-height: 100vh;

                .overlay {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                }
            }
        }

        .bar {
            position: absolute;
            width: 26vw;
            top: calc(2vh + 9.75vw);
            bottom: 2vh;
            left: 4vw;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            background-color: #fff;
            border-radius: $border-radius * 2;

            @include medium-down {
                position: static;
                margin-right: 4vw;
                margin-left: 4vw;
                margin-top: -5vw;
                margin-bottom: calc(2vh + 9.75vw);
                width: auto;
            }

            .card-mini {
                .wrapper {
                    display: flex;
                    flex-direction: row;
                    margin: 0.75rem 1.5rem;
                    &:not(:last-of-type) {
                        border-bottom: solid 1px $light-blue-bg;
                    }
                    padding: 0.75rem 0;
                    box-shadow: none;

                    .image-wrapper {
                        width: 33%;
                        .image {
                            position: relative;
                            width: 100%;
                            padding-bottom: 100%;
                            img {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: $border-radius;
                            }
                        }
                    }

                    .info {
                        width: 67%;
                        padding-right: 0.75rem;
                        .meta {
                            color: $font-color;
                            margin-bottom: 0.25em;
                            margin-right: 0.75rem;
                        }

                        h3,
                        .meta {
                            margin: 0;
                            font-size: calc(1vw + 0.3rem);
                            @include medium-down {
                                font-size: calc(2vw + 0.8rem);
                            }
                        }
                    }
                }
            }
        }
    }
}

body.pilots #project {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: bold;
        font-size: 2.8rem;
    }
    .aims {
        color: $accent-color;
        font-weight: 700;
        font-size: $small-font-size;
        margin-bottom: 5rem;
        margin-top: 0.3rem;
    }
    .summary {
        font-weight: 700;
        color: $primary-color;
    }
    .facts {
        .info {
            padding: 0 1rem;
            display: flex;
            flex-grow: 999;
            flex-direction: column;
            .description {
                margin-top: 0.5rem;
            }
            h2 {
                margin: 0;
                font-weight: $medium;
                font-size: 1.8rem;
            }
        }
        @include medium-down {
            padding-right: 11px;
            padding-left: 11px;
            > * {
                padding-right: 4px;
                padding-left: 4px;
            }
            .info {
                font-size: 2vw;
                h2 {
                    font-size: 2.2vw;
                }
            }
        }
    }
    .context {
        color: $primary-color;
        font-size: 2rem;
    }

    .fact {
        padding-bottom: 100%;

        position: relative;
        border-radius: $border-radius;
        box-shadow: 0 10px 30px 0 rgba(32, 33, 47, 0.08);

        background-color: #fff;
        display: flex;
        .wrapper {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            text-align-last: center;
            display: flex;
            .content {
                width: 100%;
            }
        }

        img {
            height: 60%;
            margin-top: 1rem;
            margin-bottom: -1.5rem;
        }
    }
    .body {
        margin-bottom: 1.5rem;
    }

    .contact {
        a {
            color: $font-color;
        }
    }
}
