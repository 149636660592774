/* Buttons
  =============== */

// Main button styling
%buttons {
    -webkit-appearance: none;
    border-radius: $button-border-radius;
    background: $button-background;
    color: $button-color;
    box-shadow: $button-box-shadow;
    font-weight: $button-font-weight;
    font-family: $button-font-family;
    font-size: $button-font-size;
    text-transform: $button-text-transform;
    padding: 1.2rem;
    border: none;

    @include medium-down {
        display: inline-block;
        margin: 0.75em 0.2em;
    }

    @include large-up {
        display: inline-block;
        margin: 0;
    }

    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
}

// Buttons on hover
%buttons-hover {
    color: darken($button-color, 10%);
    background: darken($button-background, 10%);
    text-decoration: none;
}

// Buttons on focus
%buttons-focus {
    border: $button-border-width $button-border-style
        darken($button-border-color, 10%);
    background: darken($button-background, 10%);
    color: $button-color-hover;
    text-decoration: none;
}

// Apply styles to .button class, button element, and button-based inputs
#{$buttons} {
    @extend %buttons;

    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    > *:hover,
    &:hover {
        @extend %buttons-hover;
    }
    &:active {
        background: #174489;
        box-shadow: 0 13px 20px 0 rgba(23, 68, 137, 0.4);
        border-radius: 24px;
    }
    > *:focus,
    > *:active,
    &:focus,
    &:active {
        // @extend %buttons-focus;
        box-shadow: $button-box-shadow-active;
    }
    > * {
        color: $button-color;
    }
}

// Secondary button color
.accent-button,
a.accent-button {
    color: $accent-button-color;
    border: 1px solid $accent-color;
    background: $accent-color;
    &:hover,
    &:focus,
    &:active {
        color: $accent-button-color-hover;
        border: 1px solid darken($accent-color, 10%);
        background: darken($accent-color, 10%);
    }
}

.alternative-button,
a.alternative-button {
    color: $button-color;
    border: 1px solid $button-background;
    background: $button-background;
    &:hover,
    &:focus,
    &:active {
        color: darken($button-color, 10%);
        border: 1px solid darken($button-background, 10%);
        background: darken($button-background, 10%);
    }
}

// Muted buttons
.muted-button,
a.muted-button {
    background: $muted-background;
    border: $muted-border;
    color: $muted-color;
    &:hover,
    &:focus,
    &:active {
        color: $muted-color-hover;
        border: $muted-border-hover;
        background: $muted-background-hover;
    }
}

// Disabled buttons
.disabled-button,
a.disabled-button {
    opacity: 0.7;
    background-color: #c4cdd5;
    border: #c4cdd5;
    pointer-events: none;
    cursor: default;
    &:hover,
    &:focus,
    &:active {
        opacity: 0.7;
        background-color: #c4cdd5;
        border: #c4cdd5;
    }
}

// Muted buttons
.transparent-button,
a.transparent-button {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    &:hover,
    &:focus,
    &:active {
        color: #fff;
        border: 1px solid #fff;
        background: transparent;
    }
    &:hover {
        opacity: 0.7;
    }
}
