@include large-up {
    body.contact .form {
        margin: $content-padding;
    }
}

body.contact .contact-form {
    background: #ffffff;

    @include large-up {
        box-shadow: 0 1rem 3rem 0 rgba(32, 33, 47, 0.08);
        border-radius: 1rem;
        flex-direction: row-reverse;
    }

    @include medium-down {
        flex-direction: column-reverse;
    }

    .info {
        @include large-up {
            padding: 0;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            @include large-up {
                border-radius: 1rem 0 0 1rem;
                object-fit: cover;
                object-position: right 0px top 0;
            }
            @include medium-down {
                height: 50vw;
                object-fit: cover;
            }
        }
    }

    .button {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    input,
    textarea {
        border-color: #174489;
    }
    textarea {
        height: 12rem;
    }
    h2 {
        margin-bottom: 2rem;
    }
}
