.latest-filter-buttons {
    margin-bottom: $margins;
    @include large-up {
        margin-top: 4.9rem;
    }
    text-align: center;

    .button {
        line-height: 0.6rem;
        font-weight: $font-weight;
    }

    form {
        @include medium-down {
            margin: 0.75em 0.2em;
        }

        @include large-up {
            margin: 0 0.5em 0.1em;
        }

        display: inline-block;

        justify-content: center;
        align-items: center;
        padding: 0;

        border: $borders;
        border-radius: $button-border-radius;

        input {
            background: transparent;
            border: 0;
            font-size: $smaller-font-size;
            color: $primary-color;

            &:focus {
                outline: none;
            }

            &::placeholder {
                opacity: 1;
                color: $font-color;
                line-height: 1.3;
                font-size: inherit;
            }
        }

        &:before {
            content: "";
            background: url(/dist/images/icons/search.svg) no-repeat;
            background-size: 65%;
            background-position: center;
            margin: 0 0.2em 0 0;
            height: 1.5em;
            width: 1.5em;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.latest-items {
    .latest-item {
        margin-bottom: 2em;
    }
}

.msg-no-result {
    @include large-up {
        text-align: center !important;
        text-align: center !important;
        -moz-text-align-last: center !important;
        -moz-text-align-last: center !important;
        text-align-last: center !important;
        text-align-last: center !important;
    }
}
