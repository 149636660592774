/* + 0px makes sure the value is cast as a number, so calculations can be done with it */
$xs: map_get($breakpoints, xs) + 0px;
$sm: map_get($breakpoints, sm) + 0px;
$md: map_get($breakpoints, md) + 0px;
$lg: map_get($breakpoints, lg) + 0px;
$xlg: map_get($breakpoints, xlg) + 0px;

$reflex-xs: $xs !default;
$reflex-sm: $sm !default;
$reflex-md: $md !default;
$reflex-lg: 940px !default;
$reflex-xlg: 1200px !default;

$fullwidth-large-nav-min: 1120px;
$fullwidth-xlarge-nav-min: 1520px;

/* Colors */

$light-blue: #EAF4F7;
$light-blue-bg: #eaf3f8;
$light-blue-gradient: linear-gradient(181deg, #eaf3f8 0%, #FFFFFF 78%);


$background: #fff; /* body bg */
$primary-color: #174489; /* title, nav, footer, button bg, labels */
$accent-color: #98C223; /* active menu, link color, quote */
$font-color: #4A4A4A;
$light-font-color: #637381;



$link-color: #4A4A4A;
$link-hover-color: $link-color;
$highlight: $accent-color;
$error: $primary-color;

/* Typography */

// Body font
$font-size: 62.5%;
$body-font-size: 1.8rem;
$smaller-font-size: 1.6rem; // relative to current font size
$bigger-font-size: 1.15em; // relative to current font size
$small-font-size: $body-font-size*0.8;
$tiny-font-size: $body-font-size*0.7;
$font-style: normal;
$font-variant: normal;
$font-weight: 400;
$font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
$line-height: 1.5;
$condensed-line-height: 1.4;


$text-shadow: 0 13px 20px rgba(0,21,93,0.21);

// Headings
$heading-font-color: $primary-color;
$heading-font-weight: 600;
$heading-font-family: $font-family;
$heading-line-height: 1.4;

// Heading font size
$h1: calc(1vw + 1.5rem);
$h2: 2rem;
$h3: 2rem;
$h4: 2rem;
$h5: 1.8rem;

// Mobile font size
$h1-mobile: 3.2rem;

$medium: 500;
$semibold: 600;
$bold: 700;

/* Padding */

$padding: calc(1em + 0.5vw);
$margins: 1em;
$content-padding: 1.5rem;
$content-padding-mobile: 1.8rem;

/* Borders */

$border-width: 2px;
$border-style: solid;
$border-color: $primary-color;
$border-radius: 0.4rem;
$borders: $border-width $border-style $border-color;

$box-shadow: 0 0.5rem 1.5rem 0 rgba(0,0,0,0.07), 0 1.5rem 1.5rem 0 rgba(50,50,93,0.10);
$box-shadow-heavy: 0 1.5rem 3.5rem 0 rgba(50,50,93,0.10), 0 0.5rem 1.5rem 0 rgba(0,0,0,0.07);

$overlay-gradient: linear-gradient(-180deg, rgba(0,0,0,0.00) 32%, #000000 95%);

/* Forms */

$forms: ('[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea');
$buttons: ('.button, a.button, button, [type=submit], [type=reset], [type=button]');
$input-background: $background;
$placeholder: $font-color;
$form-border: 1px solid $border-color;
$form-border-hover: 1px solid darken($border-color, 10%);
$form-border-focus: 1px solid $link-color;
$forms-font-size: 1.8rem;

/* Buttons */

$button-background: $primary-color;
$button-background-hover: transparent;
$button-color: #fff;
$button-color-hover: $primary-color;
$button-font-weight: 500;
$button-font-family: $font-family;
$button-font-size: $smaller-font-size;
$button-border-width: 0.2rem;
$button-border-style: solid;
$button-border-color: $primary-color;
$button-border-radius: 2.4rem;
$button-text-transform: none;
$button-box-shadow: 0 13px 20px 0 rgba(23,68,137,0.40);
$button-box-shadow-hover: none;
$button-box-shadow-active: 0 13px 20px 0 rgba(23,68,137,0.40);

// Accent buttons
$accent-button-background: $primary-color;
$accent-button-color: #ffffff;
$accent-button-color-hover: #ffffff;

// Muted Buttons
$muted-border: 1px solid $primary-color;
$muted-border-hover: 1px solid darken($primary-color, 30%);
$muted-background: transparent;
$muted-background-hover: transparent;
$muted-color: darken($primary-color, 50%);
$muted-color-hover: darken($primary-color, 50%);

/* Tables */

$stripes: #f8f8f8;
$caption: #ababab;

/* Code */

$code-color: $font-color;
$code-size: 14px;
$code-family: Menlo, monospace;
$code-background: transparent;
$code-borders: $borders;