
$breakpoints: (xs: 576, sm: 768, md: 992, lg: 1280, xlg: 1920);
/* variables & fonts */

@import 'fonts';
@import 'variables';
@import '~reflex-grid/scss/includes/_variables';
@import 'breakpoints';

/* prepare for ignition */

@import '~normalize.css/normalize';
@import 'base';
@import 'helpers';
@import 'init';

/* external libs */

@import '~reflex-grid/scss/includes/_mixins';
@import '~reflex-grid/scss/includes/_grid';
@import '~reflex-grid/scss/includes/_helpers';
//@import '~flag-icon-css/css/flag-icon.min.css'; /* by adding .css extension this is not processed by sass/postcss */
//@import '~swiper/dist/css/swiper.min.css'; /* by adding .css extension this is not processed by sass/postcss */
@import 'reset';

/* reset things if necessary (fi wrong defaults set by external libs) */


/* core elements */

@import 'main';
@import 'navigation';
@import 'buttons';
@import 'forms';
@import 'tables';

/* reusable modules */

@import 'Modules/card';
@import 'Modules/latest-card';
@import 'Modules/pagination';
//@import 'Modules/search-bar';
//@import 'Modules/tag';
//@import 'Modules/product-cards';
//@import 'Modules/product-list';

/* pimcore snippets */

//@import 'Snippets/country-bar';
//@import 'Snippets/latest-products';
//@import 'Snippets/latest-items';
//@import 'Snippets/stay-in-touch';
//@import 'Snippets/support';

/* page specific */

@import 'Pages/home';
@import 'Pages/projects';
@import 'Pages/library';

//@import 'Pages/products';
//@import 'Pages/product-detail';
//@import 'Pages/order-form';
@import 'Pages/latest';
//@import 'Pages/events';
//@import 'Pages/login';
@import 'Pages/contact';
//@import 'Pages/search';