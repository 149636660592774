.latest-card {
    background: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(32, 33, 47, 0.08);
    border-radius: 1rem;

    &,
    &:hover {
        text-decoration: none;
        color: $font-color;
    }

    @include medium-down {
        background: transparent;
        box-shadow: none;
    }

    @include large-up {
        .col-md-5 {
            padding: 0;
        }
    }

    img {
        @include large-up {
            min-height: 28rem;
            max-height: 28rem;
            object-fit: cover;
            width: 100%;
            display: block;
        }
        border-radius: 1rem 0 0 1rem;

        @include medium-down {
            border-radius: 1rem 1rem 0 0;
            width: 100%;
        }
    }
    .body {
        padding: 1.9rem;

        @include medium-down {
            padding-top: 0;
            padding-bottom: 0;
        }

        .type {
            font-size: $smaller-font-size;
            color: $light-font-color;
            font-weight: $medium;
            margin: 0 0 0.4rem 0;
        }
        .title {
            color: $primary-color;
            font-weight: $bold;
            line-height: 1.1;
            margin: 0;
        }
        .summary {
            margin-top: 0.4rem;
            line-height: 2.6rem;
        }
        .line {
            width: 100%;
            opacity: 0.1;
            border: 1px solid #174489;
        }
        .date {
            font-size: $smaller-font-size;
            color: $light-font-color;
            font-weight: $medium;
            margin: 0.6rem 0 0 0.3rem;
        }
    }
}
