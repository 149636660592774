.card,
body.medium-screen .card-medium-down,
body.small-screen .card-medium-down {
    display: flex;
    flex-direction: column;
    min-height: 4rem;

    .wrapper {
        position: relative;
        background: #fff;
        display: flex;
        flex-direction: column;
        flex-grow: 9999;
        border-radius: $border-radius;

        @include large-up {
            & {
                box-shadow: $box-shadow;
                padding: calc(#{$padding} * 1.2) $padding;
            }
        }
        @include medium-down {
            & {
                padding: 0.5em 0.75em;
            }
        }

        .category {
            position: absolute;
            right: 0;
            top: 1rem;
            color: #fff;
            font-weight: $medium;
            padding: 0.25rem 1rem;
            font-size: $tiny-font-size;
            background: $primary-color;
            border-radius: 1.2rem 0 0 1.2rem;
        }

        .card-info {
            margin-top: $margins;
        }

        .image {
            position: relative;
            img {
                width: 100%;
            }
        }

        h2 {
            font-size: $h3;
        }

        .meta {
            display: flex;
            position: relative;
            align-items: center;
            margin-bottom: $margins;

            .favorite-toggler {
                background-image: url("/dist/images/Stars/star-faint.png");
                cursor: pointer;
                height: 2.1rem;
                width: 2.1rem;
                position: absolute;
                right: 0;

                &:hover,
                &.active {
                    background-image: url("/dist/images/Stars/star-bright.png");
                }
            }

            .date {
                margin-left: 1em;
            }
        }

        .info {
            flex-grow: 1;
            font-size: $body-font-size * 0.9;
            line-height: $condensed-line-height;

            p {
                > b {
                    font-weight: 400;
                }
            }
        }
    }
}
