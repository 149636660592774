/* Forms
  =============== */

// Display for all input fields (except buttons and checkbox)
%forms {
    display: block;
    border: $form-border;
    border-radius: $border-radius;
    padding: 0.65em;
    outline: none;
    background: $input-background;
    margin-bottom: 0.1em;
    font-size: $forms-font-size;
    width: 100%;
    max-width: 100%;
    color: $font-color;
}

// Input fields on hover
%forms-hover {
    border: $form-border-hover;
}

// Input fields on focus
%forms-focus {
    outline-width: 0;
    border: $form-border-focus;
    /*box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1), 0 0 6px lighten($link-color, 30%);*/
}

// Variable containing all input fields
#{$forms} {
    @extend %forms;
    &:hover {
        @extend %forms-hover;
    }
    &:focus,
    &:active {
        @extend %forms-focus;
    }
}

textarea {
    overflow: auto;
    height: auto;
}

fieldset {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: $padding;
    margin: $margins 0;
}

legend {
    padding: 0 0.5rem;
    font-weight: 600;
}

// Fix issues with select
select {
    color: $heading-font-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: $background url(/dist/images/Arrows/arrow-down-purple.svg) right
        0.75em center no-repeat;
    line-height: 1; // ensures text doesn't get cut off
}

select::-ms-expand {
    display: none; // dropdown icon fix for IE
}

// Make range full width
[type="range"] {
    width: 100%;
}

// Stylable checkboxes

/* user-select prevents accidentally selecting text when clicking on the checkbox label */
.labeledCheckbox {
    margin: 0.5em 0 0.5em 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.labeledCheckbox input {
    margin-right: 0.5em;
}

.labeledCheckbox label {
    font-size: $small-font-size;
}

/*
 *  The use of the appearance property was suggested by an article by Danny Markov at:
 *      http://tutorialzine.com/2015/07/quick-tip-style-form-elements-with-pseudo-selectors/
 *
 *  The appearance property has never been fully implemented by any browser, and is considered
 *  problematic. However, as of December 2016, there is now some talk of instituting a limited form:
 *      https://drafts.csswg.org/css-ui-4/#appearance-switching
 *
 *  This works on:
 *      Mac
 *          Safari
 *          Chrome
 *          Opera
 *      Windows
 *          IE
 *          Chrome
 *      iOS
 *          Safari
 *          Chrome
 *          Opera
 *          Firefox
 *
 *  IOW, it works in everything except desktop Firefox (not tested on Android).
 *
 *  The strange thing about Firefox is that -moz-appearance does not work the same as -webkit-appearance.
 *  If -moz-appearance is omitted altogether, the default rounded/shaded blue checkbox is used, as expected.
 *  But when -moz-appearance is included, it does have some effect: it styles the box with a 2px gray inset border,
 *  white background,   and its own checkmark character, but it does allow you to specify your own height and width.
 *  In the case of this particular site, I judged that this compromise is still better than the default,
 *  and so kept -moz-appearance in the css.
 */

input[type="checkbox"] {
    appearance: none;
    position: relative;
    vertical-align: -0.35em;
    /* align box with the text label */
    width: 1.35em;
    height: 1.35em;
    color: #fff;
    background-color: #fff;
    border: $borders;
    border-radius: $border-radius;
    outline: none;
}

input[type="checkbox"]::after {
    content: "\a0"; /* Non-breaking space */
    position: absolute;
    font-size: $smaller-font-size;
    left: 0.3em;
    top: 0.2em;
}

/*
 *  Add check mark character when the checkbox is checked.
 *  Unfortunately, the alignment of the check mark within the box varied from browser to browser,
 *  (mainly iOS browsers differ from desktop browsers)
 */

input[type="checkbox"]:checked::after {
    content: "\2713"; /* Check mark character */
}

input[type="checkbox"]:checked,
input[type="checkbox"]:active {
    background-color: #2e96e3;
}

// Labels
label {
    font-weight: 600;
    max-width: 100%;
    display: block;
    margin: 1rem 0 0.5rem;
}

@include medium-up {
    .split-form {
        // Split forms have right aligned labels and margins on the column
        label {
            text-align: right;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
        }
    }
}

// Placeholder Text
::-webkit-input-placeholder,
::-moz-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
    color: $placeholder;
}

/* Custom
  =============== */

.error {
    color: $error;
    font-size: $tiny-font-size;
    font-weight: 300;
    &.invisible {
        visibility: hidden;
    }
}

.form {
    .error {
        text-align: right;
        height: 0;
        margin-bottom: 3em;
    }

    // Errors
    input,
    select,
    textarea {
        &.invalid {
            border: 1px solid $error;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1),
                0 0 6px lighten($error, 35%);
        }
        &:hover,
        &:focus,
        &:active {
            &.invalid {
                @extend .invalid;
            }
        }
    }

    h2 {
        font-size: $h3;
    }
    .field {
        label {
            font-size: $small-font-size;
            color: #5955ac;
            font-weight: 500;

            &.optional {
                &::before {
                    content: "Optional";
                    font-size: $smaller-font-size;
                    color: $font-color;
                    float: right;
                    font-weight: 500;
                }
            }
        }

        input[type="file"] {
            display: none;
        }

        .file-input-overlay {
            cursor: pointer;
            background-color: white;
            padding: 12px;
            width: 100%;
            margin-bottom: 5px;
            font-weight: 300;

            > p {
                display: inline;
                font-size: 16px;
                font-weight: bold;
            }

            > span {
                font-size: 16px;
                color: #5955ac;
                font-weight: 500;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .sub-text > p {
            font-size: $tiny-font-size;
        }
    }
}
